.Wcu-main-div {
  margin-top: 200px;
  background-image: url("../../../Wcubg.jpg"); /* Update the path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 900px;
  width: 100%;
  padding-top: 100px;
  position: relative;
}

.Wcu-internal {
  /* background-color: rgba(240, 248, 255, 0.8); */
  background-color: #fff;
  height: 700px;
}

.Wcu-main-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity to control the brightness */
  z-index: 1; /* Ensure this is below the content */
}

.Wcu-internal {
  position: relative;
  z-index: 2; /* Ensure this is above the overlay */
}

.Wcu-leftdiv {
  position: relative; /* Add this */
}

.Wcu-leftdiv::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Update the path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5; /* Adjust the opacity to control the brightness */
  z-index: -1; /* Ensure this is below the content */
}

.Wcu-leftdiv {
  position: relative; /* Add this */
}

.Wcu-leftdiv::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* Update the path to your image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5; /* Adjust the opacity to control the brightness */
  z-index: -1; /* Ensure this is below the content */
}

.internal-boxes-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.items-Wcu-div {
  background-color: transparent;
  height: 230px; /* Adjust the height as needed */
  width: 200px; /* Adjust the width as needed */
  border: none; /* Optional: Add a border */
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 30px;
  margin-bottom: 30px; /* Add margin to create space between the items */
}

.Wcu-title {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 40px;
  color: #2a447f;
  font-family: "Yeseva One", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 50px;
}

.Wcu-title-span {
  color: #c7402d;
}

/* Flexbox container for 2x2 grid */
.flex-row {
  display: flex;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.img-div-Wcu {
  height: 180px;
  width: 100%;
  border: 1px solid black;
  border-radius: 20px;
  margin-bottom: 10px;
}

.image-wcu {
  height: 100%;
  width: 100%;
}

.name-img-wcu {
  font-size: 20px;
  /* color: #c7402d; */
  /* font-weight: 700; */
  /* font-family: "Yeseva One", serif; */
}

p.name-img-wcu b {
  color: #c7402d !important;
}

.firework-div {
  height: 100%;
  padding-right: 0px;
  margin-top: 30px;
}

.firework-img {
  width: 100%;
  height: 100%;
  padding-left: 0px;
}

.firwork-side {
  margin-left: -80px;
  margin-top: 0px;
}

.waveshape {
  margin-top: -110px;
  transform: rotate(180deg);
  position: absolute;
  z-index: 2;
  width: 100%;
}

.waveshapeb {
  margin-top: 42px;

  position: absolute;
  z-index: 2;
  width: 100%;
}

@media (max-width: 767px) {
  .Wcu-main-div {
    margin-top: 300px;
    background-image: url("../../../Wcubg.jpg"); /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 900px;
    width: 100%;
    padding-top: 80px;
    position: relative; /* Add this */
  }

  .Wcu-internal {
    background-color: rgba(240, 248, 255, 0.8);
    height: auto;
  }

  .Wcu-main-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the opacity to control the brightness */
    z-index: 1; /* Ensure this is below the content */
  }

  .Wcu-internal {
    position: relative;
    z-index: 2; /* Ensure this is above the overlay */
  }

  .Wcu-leftdiv {
    position: relative;
    /* Add this */
  }

  .Wcu-leftdiv::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity to control the brightness */
    z-index: -1; /* Ensure this is below the content */
  }

  .Wcu-leftdiv {
    position: relative;
  }

  .Wcu-leftdiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity to control the brightness */
    z-index: -1; /* Ensure this is below the content */
  }

  .internal-boxes-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 85%;
  }

  .items-Wcu-div {
    background-color: transparent;
    height: auto; /* Adjust the height as needed */
    width: 170px; /* Adjust the width as needed */
    border: none; /* Optional: Add a border */
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 0px;
    padding-bottom: 25px; /* Add margin to create space between the items */
  }

  .Wcu-title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 40px;
    color: #2a447f;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 50px;
  }

  .Wcu-title-span {
    color: #c7402d;
  }

  /* Flexbox container for 2x2 grid */
  .flex-row {
    display: flex;
    justify-content: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img-div-Wcu {
    height: 180px;
    width: 100%;
    border: 1px solid black;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .image-wcu {
    height: 100%;
    width: 100%;
  }

  .name-img-wcu {
    font-size: 20px;
    font-family: "Yeseva One", serif;
  }

  .firework-div {
    display: none;
    height: 100%;
    padding-right: 0px;
    margin-top: 30px;
  }

  .firework-img {
    width: 100%;
    height: 100%;
    padding-left: 0px;
  }

  .firwork-side {
    margin-left: 0px;
    margin-top: 0px;
  }

  .waveshape {
    margin-top: -81px;
  }
  .waveshapeb {
    margin-top: 55px;
    height: 20px;
  }
}

@media only screen and (min-width: 834px) and (max-width: 1024px) and (orientation: portrait) {
  .Wcu-main-div {
    margin-top: -440px;
    background-image: url("../../../Wcubg.jpg"); /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 800px;
    width: 100%;
    padding-top: 40px;
    position: relative;
  }

  .Wcu-internal {
    background-color: rgba(240, 248, 255, 0.8);
    height: 700px;
  }

  .Wcu-main-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the opacity to control the brightness */
    z-index: 1; /* Ensure this is below the content */
  }

  .Wcu-internal {
    position: relative;
    z-index: 2; /* Ensure this is above the overlay */
  }

  .Wcu-leftdiv {
    position: relative; /* Add this */
  }

  .Wcu-leftdiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity to control the brightness */
    z-index: -1; /* Ensure this is below the content */
  }

  .Wcu-leftdiv {
    position: relative; /* Add this */
  }

  .Wcu-leftdiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity to control the brightness */
    z-index: -1; /* Ensure this is below the content */
  }

  .internal-boxes-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .items-Wcu-div {
    background-color: transparent;
    height: 230px; /* Adjust the height as needed */
    width: 200px; /* Adjust the width as needed */
    border: none; /* Optional: Add a border */
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 30px;
    margin-bottom: 30px; /* Add margin to create space between the items */
  }

  .Wcu-title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 40px;
    color: #2a447f;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 50px;
  }

  .Wcu-title-span {
    color: #c7402d;
  }

  /* Flexbox container for 2x2 grid */
  .flex-row {
    display: flex;
    justify-content: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img-div-Wcu {
    height: 180px;
    width: 100%;
    border: 1px solid black;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .image-wcu {
    height: 100%;
    width: 100%;
  }

  .name-img-wcu {
    font-size: 20px;
    font-family: "Yeseva One", serif;
  }

  .firework-div {
    height: 100%;
    padding-right: 0px;
    margin-top: 30px;
  }

  .firework-img {
    width: 100%;
    height: 90%;
    padding-left: 0px;
  }

  .firwork-side {
    margin-left: -20px;
    margin-top: 0px;
  }

  @media (max-width: 767px) {
    .Wcu-main-div {
      background-image: url("../../../Wcubg.jpg"); /* Update the path to your image */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 800px;
      width: 100%;
      padding-top: 40px;
      position: relative; /* Add this */
    }

    .Wcu-internal {
      background-color: rgba(240, 248, 255, 0.8);
      height: auto;
    }

    .Wcu-main-div::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(
        0,
        0,
        0,
        0.5
      ); /* Adjust the opacity to control the brightness */
      z-index: 1; /* Ensure this is below the content */
    }

    .Wcu-internal {
      position: relative;
      z-index: 2; /* Ensure this is above the overlay */
    }

    .Wcu-leftdiv {
      position: relative;
      /* Add this */
    }

    .Wcu-leftdiv::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      /* Update the path to your image */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.5; /* Adjust the opacity to control the brightness */
      z-index: -1; /* Ensure this is below the content */
    }

    .Wcu-leftdiv {
      position: relative;
    }

    .Wcu-leftdiv::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      /* Update the path to your image */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.5; /* Adjust the opacity to control the brightness */
      z-index: -1; /* Ensure this is below the content */
    }

    .internal-boxes-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 85%;
    }

    .items-Wcu-div {
      background-color: transparent;
      height: auto; /* Adjust the height as needed */
      width: 200px; /* Adjust the width as needed */
      border: none; /* Optional: Add a border */
      margin-left: 10px;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 0px;
      padding-bottom: 25px; /* Add margin to create space between the items */
    }

    .Wcu-title {
      margin-bottom: 10px;
      margin-top: 10px;
      font-size: 40px;
      color: #2a447f;
      font-family: "Yeseva One", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 50px;
    }

    .Wcu-title-span {
      color: #c7402d;
    }

    /* Flexbox container for 2x2 grid */
    .flex-row {
      display: flex;
      justify-content: center;
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .img-div-Wcu {
      height: 180px;
      width: 100%;
      border: 1px solid black;
      border-radius: 20px;
      margin-bottom: 10px;
    }

    .image-wcu {
      height: 100%;
      width: 100%;
    }

    .name-img-wcu {
      font-size: 20px;
      font-family: "Yeseva One", serif;
    }

    .firework-div {
      display: none;
      height: 100%;
      padding-right: 0px;
      margin-top: 30px;
    }

    .firework-img {
      width: 100%;
      height: 100%;
      padding-left: 0px;
    }

    .firwork-side {
      margin-left: 0px;
      margin-top: 0px;
    }
  }
}

@media only screen and (min-device-width: 818px) and (max-device-width: 824px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .Wcu-main-div {
    margin-top: 300px;
    background-image: url("../../../Wcubg.jpg"); /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 900px;
    width: 100%;

    position: relative; /* Add this */
    padding-top: 100px;
    padding-bottom: 00px;
  }

  .Wcu-internal {
    background-color: rgba(240, 248, 255, 0.8);
    height: 700px;
  }

  .Wcu-main-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the opacity to control the brightness */
    z-index: 1; /* Ensure this is below the content */
  }

  .Wcu-internal {
    position: relative;
    z-index: 2; /* Ensure this is above the overlay */
  }

  .Wcu-leftdiv {
    position: relative; /* Add this */
  }

  .Wcu-leftdiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity to control the brightness */
    z-index: -1; /* Ensure this is below the content */
  }

  .Wcu-leftdiv {
    position: relative; /* Add this */
  }

  .Wcu-leftdiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity to control the brightness */
    z-index: -1; /* Ensure this is below the content */
  }

  .internal-boxes-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .items-Wcu-div {
    background-color: transparent;
    height: 230px; /* Adjust the height as needed */
    width: 200px; /* Adjust the width as needed */
    border: none; /* Optional: Add a border */
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px; /* Add margin to create space between the items */
  }

  .Wcu-title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 40px;
    color: #2a447f;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 50px;
  }

  .Wcu-title-span {
    color: #c7402d;
  }

  /* Flexbox container for 2x2 grid */
  .flex-row {
    display: flex;
    justify-content: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img-div-Wcu {
    height: 180px;
    width: 100%;
    border: 1px solid black;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .image-wcu {
    height: 100%;
    width: 100%;
  }

  .name-img-wcu {
    font-size: 20px;
    font-family: "Yeseva One", serif;
  }

  .firework-div {
    height: 100%;
    padding-right: 10px;
    margin-top: 30px;
  }

  .firework-img {
    width: 100%;
    height: 100%;
    padding-left: 0px;
  }

  .firwork-side {
    margin-left: -10px;
    margin-top: 0px;
  }

  .waveshapeb {
    margin-top: 70px;
  }
}

@media only screen and (min-device-width: 1178px) and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .Wcu-main-div {
    margin-top: 300px;
    background-image: url("../../../Wcubg.jpg"); /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 900px;
    width: 100%;

    position: relative; /* Add this */
    padding-top: 100px;
    padding-bottom: 00px;
  }

  .Wcu-internal {
    background-color: rgba(240, 248, 255, 0.8);
    height: 700px;
  }

  .Wcu-main-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the opacity to control the brightness */
    z-index: 1; /* Ensure this is below the content */
  }

  .Wcu-internal {
    position: relative;
    z-index: 2; /* Ensure this is above the overlay */
  }

  .Wcu-leftdiv {
    position: relative; /* Add this */
  }

  .Wcu-leftdiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity to control the brightness */
    z-index: -1; /* Ensure this is below the content */
  }

  .Wcu-leftdiv {
    position: relative; /* Add this */
  }

  .Wcu-leftdiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity to control the brightness */
    z-index: -1; /* Ensure this is below the content */
  }

  .internal-boxes-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .items-Wcu-div {
    background-color: transparent;
    height: 230px; /* Adjust the height as needed */
    width: 200px; /* Adjust the width as needed */
    border: none; /* Optional: Add a border */
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px; /* Add margin to create space between the items */
  }

  .Wcu-title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 40px;
    color: #2a447f;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 50px;
  }

  .Wcu-title-span {
    color: #c7402d;
  }

  /* Flexbox container for 2x2 grid */
  .flex-row {
    display: flex;
    justify-content: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img-div-Wcu {
    height: 180px;
    width: 100%;
    border: 1px solid black;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .image-wcu {
    height: 100%;
    width: 100%;
  }

  .name-img-wcu {
    font-size: 20px;
    font-family: "Yeseva One", serif;
  }

  .firework-div {
    height: 100%;
    padding-right: 10px;
    margin-top: 30px;
  }

  .firework-img {
    width: 100%;
    height: 100%;
    padding-left: 0px;
  }

  .firwork-side {
    margin-left: -10px;
    margin-top: 0px;
  }

  .waveshapeb {
    margin-top: 71px;
  }
}

@media only screen and (min-device-width: 1022px) and (max-device-width: 1028px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .Wcu-main-div {
    margin-top: -300px;
    background-image: url("../../../Wcubg.jpg"); /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 900px;
    width: 100%;

    position: relative; /* Add this */
    padding-top: 100px;
    padding-bottom: 00px;
  }

  .Wcu-internal {
    background-color: rgba(240, 248, 255, 0.8);
    height: 700px;
  }

  .Wcu-main-div::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the opacity to control the brightness */
    z-index: 1; /* Ensure this is below the content */
  }

  .Wcu-internal {
    position: relative;
    z-index: 2; /* Ensure this is above the overlay */
  }

  .Wcu-leftdiv {
    position: relative; /* Add this */
  }

  .Wcu-leftdiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity to control the brightness */
    z-index: -1; /* Ensure this is below the content */
  }

  .Wcu-leftdiv {
    position: relative; /* Add this */
  }

  .Wcu-leftdiv::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* Update the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5; /* Adjust the opacity to control the brightness */
    z-index: -1; /* Ensure this is below the content */
  }

  .internal-boxes-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .items-Wcu-div {
    background-color: transparent;
    height: 230px; /* Adjust the height as needed */
    width: 200px; /* Adjust the width as needed */
    border: none; /* Optional: Add a border */
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px; /* Add margin to create space between the items */
  }

  .Wcu-title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 40px;
    color: #2a447f;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 50px;
  }

  .Wcu-title-span {
    color: #c7402d;
  }

  /* Flexbox container for 2x2 grid */
  .flex-row {
    display: flex;
    justify-content: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .img-div-Wcu {
    height: 180px;
    width: 100%;
    border: 1px solid black;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .image-wcu {
    height: 100%;
    width: 100%;
  }

  .name-img-wcu {
    font-size: 20px;
    font-family: "Yeseva One", serif;
  }

  .firework-div {
    height: 100%;
    padding-right: 10px;
    margin-top: 30px;
  }

  .firework-img {
    width: 100%;
    height: 100%;
    padding-left: 0px;
  }

  .firwork-side {
    margin-left: -10px;
    margin-top: 0px;
  }

  .waveshapeb {
    margin-top: 70px;
  }
}
