@import url("https://fonts.googleapis.com/css2?family=Yeseva+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Parkinsans:wght@300..800&display=swap");
.footer {
  background-color: #2b4383;
  color: #ffffff;
}

.footer-logo {
  /* height: 100px; */
}

.footer-logo-img {
  /* margin-top: -50px; */
  width: 200px;
  height: 200px;
  filter: invert(300%);
}

.footer-company-name,
.footer-address,
.footer-phone {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  font-family: "Parkinsans", sans-serif;
  font-optical-sizing: auto;

  font-style: normal;
}

.footer-title {
  margin-bottom: 15px;
  font-weight: bold;
  font-family: "Parkinsans", sans-serif;
  font-optical-sizing: auto;

  font-style: normal;
}

.powe#c7402d{
  font-family: "Parkinsans", sans-serif;
  font-optical-sizing: auto;

  font-style: normal;
}

.footer-links {
  padding: 0;
  font-family: "Parkinsans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 2;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
}

.footer-link:hover {
  text-decoration: underline;
}

.social-links {
  display: flex;
  width: 100%;
  /* justify-content: center; */
  gap: 20px;
}

.social-icon {
  color: #ffffff;
  font-size: 30px;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #007bff;
}

.footer-useful-links {
  font-size: 19px;
  color: #ffffff;
  font-family: "Parkinsans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.footer-useful-links a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 5px;
}

.footer-useful-links a:hover {
  text-decoration: none;
  color: orange;
}
