@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&display=swap");
.main-banner {
  position: relative;
  height: 100vh; /* Full height */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden; /* Ensure child elements do not overflow */
}

.carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Ensure the carousel is behind other content */
}

.carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full height */
}

.carousel-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: brightness(50%);
}

.carousel-caption {
  position: absolute;
  top: 0%;

  color: white; /* Text color */
  z-index: 2; /* Ensure it appears above the carousel */
  pointer-events: none; /* Ensure the caption does not block interactions */
}

.hero-text {
  color: white; /* Text color */
  font-family: "Philosopher", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  padding: 20px; /* Add some padding */
  border-radius: 10px;
  margin-top: 180px; /* Optional: Add rounded corners */
}

.hero-text h1 {
  font-size: 65px;
}

/* Overlay image common styles */
.carousel-overlay-image1 {
  position: absolute;
  bottom: 80px; /* Adjust the bottom offset as needed */
  right: 40px; /* Adjust the right offset as needed */
  /* Adjust to center vertically */
  width: 400px; /* Adjust the width as needed */
  height: 320px; /* Maintain aspect ratio */
  z-index: 1;
}

.carousel-overlay-image2 {
  position: absolute;
  bottom: 80px; /* Adjust the bottom offset as needed */
  right: 140px; /* Adjust the right offset as needed */
  /* Adjust to center vertically */
  width: 300px; /* Adjust the width as needed */
  height: 310px;
  /* Maintain aspect ratio */
  z-index: 1;
}

.carousel-overlay-image2 .overlay-image-2 {
  height: 250px;
  width: 300px;
}
.carousel-overlay-image3 {
  position: absolute;
  bottom: 80px; /* Adjust the bottom offset as needed */
  right: 140px; /* Adjust the right offset as needed */
  /* Adjust to center vertically */
  width: 300px; /* Adjust the width as needed */
  height: 310px;
  /* Maintain aspect ratio */
  z-index: 1;
}

.carousel-overlay-image3 .overlay-image-3 {
  height: 250px;
  width: 300px;
}

.carousel-overlay-image4 {
  position: absolute;
  bottom: 80px; /* Adjust the bottom offset as needed */
  right: 140px; /* Adjust the right offset as needed */
  /* Adjust to center vertically */
  width: 300px; /* Adjust the width as needed */
  height: 310px;
  /* Maintain aspect ratio */
  z-index: 1;
}

.carousel-overlay-image4 .overlay-image-4 {
  height: 250px;
  width: 300px;
}
.hero-navbar {
  width: 100%;
  display: flex;
  align-items: center;
  height: 150px;
  background-color: transparent;
  position: absolute; /* Positioned absolutely */
  bottom: 0; /* Positioned at the bottom */
  z-index: 10;
  transition: all 0.3s ease; /* Smooth transition for background and height changes */
}

.hero-navbar::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background-color: white; /* Blue overlay background color */
  clip-path: polygon(0 0, 14% 0, 20% 80px, 100% 80px, 100% 100%, 0 100%);
  z-index: -1; /* Place it behind the navbar content */
}

.hero-navbar-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative; /* Make sure it respects the stacking context */
  z-index: 1; /* Bring the navbar content above the blue overlay */
}

.hero-navbar-brand {
  display: flex;
  font-size: 70px;
  font-weight: bold;
  color: #2a447f;
  padding: 10px 20px;
  margin-right: 0px;
  transition: all 0.3s ease;
  height: 100%;
}

.hero-logoimg {
  height: 200px;
  width: 200px;
  transition: all 0.3s ease;
  margin-left: 0px;
}

.hero-navbar-nav {
  display: flex;
  align-items: center;
  gap: 20px; /* Add spacing between nav links */
  /* font-family: "Oswald", sans-serif;  */
  /* Font family for all nav links */
  font-size: 17px;
  margin-left: 80px;
  font-weight: 600;
  text-decoration: none !important;
  margin-top: 70px; /* Position the nav items */
}

.hero-navbar-nav .nav-link {
  color: #000000 !important; /* Default color */
  width: 145px;
  text-align: center;
  transition: background-color 0.3s ease; /* Smooth transition */
  /* border-radius: 5px; */
}

.hero-navbar-nav .nav-link:hover {
  /* background-color: #c7402d; */
  color: #c7402d !important;
}

.hero-navbar-nav .nav-link.active-nav-link,
.hero-navbar-nav .nav-link.active {
  /* background-color: #c7402d !important;  */
  /* color: #ffffff !important; */
  /* border-radius: 5px; */
    color: #c7402d !important;
    border-bottom: 2px solid red;
}
.sticky-navbar {
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  border-top: 9px solid rgb(7, 7, 135);

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px; /* Adjusted height for larger logo */
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 20;
  box-shadow: 0 -8px 15px rgba(0, 0, 0, 0.1),
    /* Shadow projecting at the top */ 0 8px 15px rgba(0, 0, 0, 0.2),
    /* Larger shadow at the bottom */ 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow at the bottom */
  transition: background-color 0.3s ease, height 0.3s ease, box-shadow 0.3s ease; /* Smooth shadow transition */
}

/* Pseudo-element to blur content above the navbar */

.sticky-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space out logo and menu */
  width: 100%;
  padding: 0 20px;
}

.sticky-logo-img {
  height: 140px; /* Increased logo size */
  width: auto;
  margin-right: 190px; /* Adjusted margin to separate logo and menu */
  transition: transform 0.3s ease; /* Add hover animation for logo */
}

.sticky-logo-img:hover {
  transform: scale(1.1); /* Slight enlargement on hover */
}

.sticky-navbar-nav {
  display: flex;
  align-items: center;
  gap: 20px;
  /* font-family: "Oswald", sans-serif;  */
  font-size: 17px; /* Slightly larger font */
  font-weight: 600;
  text-align: center;
}

.sticky-navbar-nav .nav-link {
  color: black; /* White text for links */
  text-decoration: none;
  width: 145px;
  padding: 8px 15px; /* Add padding for button-like feel */
  /* border-radius: 5px;  */
  /* transition: background-color 0.3s ease, color 0.3s ease;  */
}

.sticky-navbar-nav .nav-link:hover {
  /* background-color: #c7402d;
  color: #ffffff; */
  color: #c7402d !important;
}

.sticky-navbar-nav .nav-link.active-nav-link,
.sticky-navbar-nav .nav-link.active {
  /* background-color: #c7402d;
  color: #ffffff !important;
  border-radius: 5px; */
  color: #c7402d !important;
    border-bottom: 2px solid red;
}

/* Style for the custom dropdown content with three columns */
.custom-dropdown-content {
  display: flex;
  gap: 20px;
  padding: 0px;
  padding-left: 10px;
  padding-right: 0px;
  width: 100%;
  max-width: 1200px;
  height: 210px; /* Set a height to make it scrollable */
  overflow: hidden;
  margin: 0 auto;
  margin-top: 20px;
}

/* Scrollable columns */
.dropdown-column {
  flex: 1;
  font-family: "Oswald", sans-serif;
  padding: 1000px;
  border-right: 1px solid #ddd;
}

/* Remove right border for the last column */
.dropdown-column:last-child {
  border-right: none;
}

/* Style for titles */
.dropdown-title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 17px;
  cursor: pointer;
}

/* Circular images */
.dropdown-image {
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 20px;
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-bottom: 10px;
}

/* Sticky dropdown menu styling to center on the screen */
.sticky-dropdown-menu {
  background-color: #fff;
  width: 1200px;
  height: 240px;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  position: absolute;
  left: 40%;
  top: 60%;
  transform: translate(-78%, 7%);
  z-index: 2000;
}

/* Style for the hero dropdown content with three columns */

@media only screen and (min-width: 834px) and (max-width: 1024px) and (orientation: portrait) {
  .main-banner {
    position: relative;
    height: 100vh; /* Full height */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden; /* Ensure child elements do not overflow */
  }

  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Ensure the carousel is behind other content */
  }

  .carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height */
  }

  .carousel-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: brightness(70%);
  }

  .carousel-caption {
    position: absolute;
    top: 0%;

    color: white; /* Text color */
    z-index: 2; /* Ensure it appears above the carousel */
    pointer-events: none; /* Ensure the caption does not block interactions */
  }

  .hero-text {
    color: white; /* Text color */
    font-family: "Philosopher", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    padding: 20px; /* Add some padding */
    border-radius: 10px;
    margin-top: 320px; /* Optional: Add rounded corners */
  }

  .hero-text h1 {
    font-size: 100px;
  }

  /* Overlay image common styles */
  .carousel-overlay-image1 {
    position: absolute;
    bottom: 80px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 400px; /* Adjust the width as needed */
    height: 390px; /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image2 {
    position: absolute;
    bottom: 80px; /* Adjust the bottom offset as needed */
    right: 10px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 330px; /* Adjust the width as needed */
    height: 380px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image2 .overlay-image-2 {
    height: 250px;
    width: 300px;
  }
  .carousel-overlay-image3 {
    position: absolute;
    bottom: 130px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 300px; /* Adjust the width as needed */
    height: 310px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image3 .overlay-image-3 {
    height: 300px;
    width: 320px;
  }

  .carousel-overlay-image4 {
    position: absolute;
    bottom: 180px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 340px; /* Adjust the width as needed */
    height: 390px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image4 .overlay-image-4 {
    height: 390px;
    width: 350px;
  }

  .hero-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    height: 150px;
    background-color: transparent;
    position: absolute; /* Positioned absolutely */
    bottom: 0; /* Positioned at the bottom */
    z-index: 10;
    transition: all 0.3s ease; /* Smooth transition for background and height changes */
  }

  .hero-navbar::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background-color: white; /* Blue overlay background color */
    clip-path: polygon(0 0, 14% 0, 20% 80px, 100% 80px, 100% 100%, 0 100%);
    z-index: -1; /* Place it behind the navbar content */
  }

  .hero-navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative; /* Make sure it respects the stacking context */
    z-index: 1; /* Bring the navbar content above the blue overlay */
  }

  .hero-navbar-brand {
    display: flex;
    font-size: 70px;
    font-weight: bold;
    color: #2a447f;
    padding: 10px 20px;
    margin-right: 0px;
    transition: all 0.3s ease;
    height: 100%;
  }

  .hero-logoimg {
    height: 180px;
    width: 190px;
    transition: all 0.3s ease;
    margin-left: -20px;
  }

  .hero-navbar-nav {
    display: flex;
    align-items: center;
    gap: 10px; /* Add spacing between nav links */
    font-family: "Oswald", sans-serif; /* Font family for all nav links */
    font-size: 17px;
    margin-left: 10px;
    font-weight: 600;
    text-decoration: none !important;
    margin-top: 70px; /* Position the nav items */
  }

  .hero-navbar-nav .nav-link {
    color: #000000 !important; /* Default color */
    width: 115px;
    text-align: center;
    transition: background-color 0.3s ease; /* Smooth transition */
    border-radius: 5px; /* Add rounded corners */
  }

  .hero-navbar-nav .nav-link:hover {
    background-color: #c7402d; /* Background color on hover */
  }

  .hero-navbar-nav .nav-link.active-nav-link,
  .hero-navbar-nav .nav-link.active {
    background-color: #c7402d !important; /* Active background color */
    color: #ffffff !important; /* Active text color for better contrast */
    border-radius: 5px; /* Maintain rounded corners */
  }
  .sticky-navbar {
    width: 100%;
    border-top: 9px solid rgb(7, 7, 135);

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px; /* Adjusted height for larger logo */
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 20;
    box-shadow: 0 -8px 15px rgba(0, 0, 0, 0.1),
      /* Shadow projecting at the top */ 0 8px 15px rgba(0, 0, 0, 0.2),
      /* Larger shadow at the bottom */ 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow at the bottom */
    transition: background-color 0.3s ease, height 0.3s ease,
      box-shadow 0.3s ease; /* Smooth shadow transition */
  }

  /* Pseudo-element to blur content above the navbar */

  .sticky-navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space out logo and menu */
    width: 100%;
    padding: 0 20px;
  }

  .sticky-logo-img {
    height: 140px; /* Increased logo size */
    width: auto;
    margin-right: 0px; /* Adjusted margin to separate logo and menu */
    transition: transform 0.3s ease; /* Add hover animation for logo */
  }

  .sticky-logo-img:hover {
    transform: scale(1.1); /* Slight enlargement on hover */
  }

  .sticky-navbar-nav {
    display: flex;
    align-items: center;
    gap: 15px;
    font-family: "Oswald", sans-serif; /* Stylish font */
    font-size: 17px; /* Slightly larger font */
    font-weight: 600;
    text-align: center;
  }

  .sticky-navbar-nav .nav-link {
    color: black; /* White text for links */
    text-decoration: none;
    width: 120px;
    padding: 8px 15px; /* Add padding for button-like feel */
    border-radius: 5px; /* Rounded corners for nav links */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
  }

  .sticky-navbar-nav .nav-link:hover {
    background-color: #c7402d; /* #c7402dbackground on hover */
    color: #ffffff; /* White text on hover */
  }

  .sticky-navbar-nav .nav-link.active-nav-link,
  .sticky-navbar-nav .nav-link.active {
    background-color: #c7402d; /* Active state background */
    color: #ffffff; /* White text for active state */
    border-radius: 5px; /* Maintain rounded corners */
  }
  .sticky-dropdown-menu {
    background-color: #fff;
    width: 800px;
    height: 240px;
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    position: absolute;
    left: 40%;
    top: 60%;
    transform: translate(-100%, 8%);
    z-index: 2000;
  }
}

@media only screen and (min-width: 1364px) and (max-width: 1367px) and (orientation: landscape) {
  .main-banner {
    position: relative;
    height: 100vh; /* Full height */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden; /* Ensure child elements do not overflow */
  }

  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Ensure the carousel is behind other content */
  }

  .carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height */
  }

  .carousel-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: brightness(70%);
  }

  .carousel-caption {
    position: absolute;
    top: 0%;

    color: white; /* Text color */
    z-index: 2; /* Ensure it appears above the carousel */
    pointer-events: none; /* Ensure the caption does not block interactions */
  }

  .hero-text {
    color: white; /* Text color */
    font-family: "Philosopher", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    padding: 20px; /* Add some padding */
    border-radius: 10px;
    margin-top: 320px; /* Optional: Add rounded corners */
  }

  .hero-text h1 {
    font-size: 100px;
  }

  /* Overlay image common styles */
  .carousel-overlay-image1 {
    position: absolute;
    bottom: 80px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 400px; /* Adjust the width as needed */
    height: 390px; /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image2 {
    position: absolute;
    bottom: 80px; /* Adjust the bottom offset as needed */
    right: 10px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 330px; /* Adjust the width as needed */
    height: 380px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image2 .overlay-image-2 {
    height: 250px;
    width: 300px;
  }
  .carousel-overlay-image3 {
    position: absolute;
    bottom: 130px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 300px; /* Adjust the width as needed */
    height: 310px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image3 .overlay-image-3 {
    height: 300px;
    width: 320px;
  }

  .carousel-overlay-image4 {
    position: absolute;
    bottom: 180px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 340px; /* Adjust the width as needed */
    height: 390px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image4 .overlay-image-4 {
    height: 390px;
    width: 350px;
  }

  hero-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    height: 150px;
    background-color: transparent;
    position: absolute; /* Positioned absolutely */
    bottom: 0; /* Positioned at the bottom */
    z-index: 10;
    transition: all 0.3s ease; /* Smooth transition for background and height changes */
  }

  .hero-navbar::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background-color: white; /* Blue overlay background color */
    clip-path: polygon(0 0, 14% 0, 20% 80px, 100% 80px, 100% 100%, 0 100%);
    z-index: -1; /* Place it behind the navbar content */
  }

  .hero-navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative; /* Make sure it respects the stacking context */
    z-index: 1; /* Bring the navbar content above the blue overlay */
  }

  .hero-navbar-brand {
    display: flex;
    font-size: 70px;
    font-weight: bold;
    color: #2a447f;
    padding: 10px 20px;
    margin-right: 0px;
    transition: all 0.3s ease;
    height: 100%;
  }

  .hero-logoimg {
    height: 180px;
    width: 190px;
    transition: all 0.3s ease;
    margin-left: -20px;
  }

  .hero-navbar-nav {
    display: flex;
    align-items: center;
    gap: 10px; /* Add spacing between nav links */
    font-family: "Oswald", sans-serif; /* Font family for all nav links */
    font-size: 17px;
    margin-left: 10px;
    font-weight: 600;
    text-decoration: none !important;
    margin-top: 70px; /* Position the nav items */
  }

  .hero-navbar-nav .nav-link {
    color: #000000 !important; /* Default color */
    width: 115px;
    text-align: center;
    transition: background-color 0.3s ease; /* Smooth transition */
    border-radius: 5px; /* Add rounded corners */
  }

  .hero-navbar-nav .nav-link:hover {
    background-color: #c7402d; /* Background color on hover */
  }

  .hero-navbar-nav .nav-link.active-nav-link,
  .hero-navbar-nav .nav-link.active {
    background-color: #c7402d !important; /* Active background color */
    color: #ffffff !important; /* Active text color for better contrast */
    border-radius: 5px; /* Maintain rounded corners */
  }
  .sticky-navbar {
    width: 100%;
    border-top: 9px solid rgb(7, 7, 135);

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px; /* Adjusted height for larger logo */
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 20;
    box-shadow: 0 -8px 15px rgba(0, 0, 0, 0.1),
      /* Shadow projecting at the top */ 0 8px 15px rgba(0, 0, 0, 0.2),
      /* Larger shadow at the bottom */ 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow at the bottom */
    transition: background-color 0.3s ease, height 0.3s ease,
      box-shadow 0.3s ease; /* Smooth shadow transition */
  }

  /* Pseudo-element to blur content above the navbar */

  .sticky-navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space out logo and menu */
    width: 100%;
    padding: 0 20px;
  }

  .sticky-logo-img {
    height: 140px; /* Increased logo size */
    width: auto;
    margin-right: 40px; /* Adjusted margin to separate logo and menu */
    transition: transform 0.3s ease; /* Add hover animation for logo */
  }

  .sticky-logo-img:hover {
    transform: scale(1.1); /* Slight enlargement on hover */
  }

  .sticky-navbar-nav {
    display: flex;
    align-items: center;
    gap: 15px;
    font-family: "Oswald", sans-serif; /* Stylish font */
    font-size: 17px; /* Slightly larger font */
    font-weight: 600;
    text-align: center;
  }

  .sticky-navbar-nav .nav-link {
    color: black; /* White text for links */
    text-decoration: none;
    width: 120px;
    padding: 8px 15px; /* Add padding for button-like feel */
    border-radius: 5px; /* Rounded corners for nav links */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
  }

  .sticky-navbar-nav .nav-link:hover {
    background-color: #c7402d; /* #c7402dbackground on hover */
    color: #ffffff; /* White text on hover */
  }

  .sticky-navbar-nav .nav-link.active-nav-link,
  .sticky-navbar-nav .nav-link.active {
    background-color: #c7402d; /* Active state background */
    color: #ffffff; /* White text for active state */
    border-radius: 5px; /* Maintain rounded corners */
  }
  .sticky-dropdown-menu {
    background-color: #fff;
    width: 800px;
    height: 240px;
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    position: absolute;
    left: 40%;
    top: 60%;
    transform: translate(-100%, 8%);
    z-index: 2000;
  }
}

/* ipad air and ipad pro */
@media only screen and (min-device-width: 766px) and (max-device-width: 900px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .main-banner {
    position: relative;
    height: 100vh; /* Full height */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden; /* Ensure child elements do not overflow */
  }

  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Ensure the carousel is behind other content */
  }

  .carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height */
  }

  .carousel-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: brightness(70%);
  }

  .carousel-caption {
    position: absolute;
    top: 0%;

    color: white; /* Text color */
    z-index: 2; /* Ensure it appears above the carousel */
    pointer-events: none; /* Ensure the caption does not block interactions */
  }

  .hero-text {
    color: white; /* Text color */
    font-family: "Philosopher", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    padding: 20px; /* Add some padding */
    border-radius: 10px;
    margin-top: 200px; /* Optional: Add rounded corners */
  }

  .hero-text h1 {
    font-size: 100px;
  }

  /* Overlay image common styles */
  .carousel-overlay-image1 {
    position: absolute;
    bottom: 80px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 400px; /* Adjust the width as needed */
    height: 390px; /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image2 {
    position: absolute;
    bottom: 80px; /* Adjust the bottom offset as needed */
    right: 10px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 330px; /* Adjust the width as needed */
    height: 380px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image2 .overlay-image-2 {
    height: 250px;
    width: 300px;
  }
  .carousel-overlay-image3 {
    position: absolute;
    bottom: 130px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 300px; /* Adjust the width as needed */
    height: 310px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image3 .overlay-image-3 {
    height: 300px;
    width: 320px;
  }

  .carousel-overlay-image4 {
    position: absolute;
    bottom: 180px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 340px; /* Adjust the width as needed */
    height: 390px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image4 .overlay-image-4 {
    height: 390px;
    width: 350px;
  }

  .mobile-navbar {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: white;
    padding-left: 20px;
    padding-right: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
  }

  .mobile-navbar-logo {
    height: 150px;
    width: 150px;
  }

  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 20px;
    cursor: pointer;
  }

  .hamburger-icon .bar {
    width: 25px;
    height: 3px;
    background-color: black;
    transition: all 0.3s ease;
  }

  .hamburger-icon .bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger-icon .bar.open:nth-child(2) {
    opacity: 0;
  }

  .hamburger-icon .bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  .mobile-menu-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color: black;
    cursor: pointer;
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mobile-nav .nav-link {
    color: black;
    padding: 10px;
    text-decoration: none;
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    width: 100%;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .mobile-nav .nav-link:hover {
    background-color: #f0f0f0;
  }
  .mobile-nav .nav-link {
    color: black;
    padding: 10px;
    text-decoration: none;
    font-size: 18px;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s ease;
  }

  .mobile-nav .nav-link:hover {
    background-color: #c7402d; /* Background color on hover */
    color: white; /* Optional: Change text color to white on hover */
  }

  .mobile-nav .nav-link.active {
    background-color: #c7402d; /* Background color for active item */
    color: white; /* Text color for active item */
  }
}
@media only screen and (min-device-width: 1178px) and (max-device-width: 1182px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .main-banner {
    position: relative;
    height: 100vh; /* Full height */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden; /* Ensure child elements do not overflow */
  }

  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Ensure the carousel is behind other content */
  }

  .carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Full height */
  }

  .carousel-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: brightness(70%);
  }

  .carousel-caption {
    position: absolute;
    top: 0%;

    color: white; /* Text color */
    z-index: 2; /* Ensure it appears above the carousel */
    pointer-events: none; /* Ensure the caption does not block interactions */
  }

  .hero-text {
    color: white; /* Text color */
    font-family: "Philosopher", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    padding: 20px; /* Add some padding */
    border-radius: 10px;
    margin-top: 160px; /* Optional: Add rounded corners */
  }

  .hero-text h1 {
    font-size: 80px;
  }

  /* Overlay image common styles */
  .carousel-overlay-image1 {
    position: absolute;
    bottom: 80px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 400px; /* Adjust the width as needed */
    height: 390px; /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image2 {
    position: absolute;
    bottom: 80px; /* Adjust the bottom offset as needed */
    right: 10px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 330px; /* Adjust the width as needed */
    height: 380px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image2 .overlay-image-2 {
    height: 250px;
    width: 300px;
  }
  .carousel-overlay-image3 {
    position: absolute;
    bottom: 130px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 300px; /* Adjust the width as needed */
    height: 310px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image3 .overlay-image-3 {
    height: 300px;
    width: 320px;
  }

  .carousel-overlay-image4 {
    position: absolute;
    bottom: 180px; /* Adjust the bottom offset as needed */
    right: 40px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 340px; /* Adjust the width as needed */
    height: 390px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image4 .overlay-image-4 {
    height: 390px;
    width: 350px;
  }

  hero-navbar {
    width: 100%;
    display: flex;
    align-items: center;
    height: 150px;
    background-color: transparent;
    position: absolute; /* Positioned absolutely */
    bottom: 0; /* Positioned at the bottom */
    z-index: 10;
    transition: all 0.3s ease; /* Smooth transition for background and height changes */
  }

  .hero-navbar::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background-color: white; /* Blue overlay background color */
    clip-path: polygon(0 0, 14% 0, 20% 80px, 100% 80px, 100% 100%, 0 100%);
    z-index: -1; /* Place it behind the navbar content */
  }

  .hero-navbar-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative; /* Make sure it respects the stacking context */
    z-index: 1; /* Bring the navbar content above the blue overlay */
  }

  .hero-navbar-brand {
    display: flex;
    font-size: 70px;
    font-weight: bold;
    color: #2a447f;
    padding: 10px 20px;
    margin-right: 0px;
    transition: all 0.3s ease;
    height: 100%;
  }

  .hero-logoimg {
    height: 180px;
    width: 190px;
    transition: all 0.3s ease;
    margin-left: -20px;
  }

  .hero-navbar-nav {
    display: flex;
    align-items: center;
    gap: 10px; /* Add spacing between nav links */
    font-family: "Oswald", sans-serif; /* Font family for all nav links */
    font-size: 17px;
    margin-left: 10px;
    font-weight: 600;
    text-decoration: none !important;
    margin-top: 70px; /* Position the nav items */
  }

  .hero-navbar-nav .nav-link {
    color: #000000 !important; /* Default color */
    width: 115px;
    text-align: center;
    transition: background-color 0.3s ease; /* Smooth transition */
    border-radius: 5px; /* Add rounded corners */
  }

  .hero-navbar-nav .nav-link:hover {
    background-color: #c7402d; /* Background color on hover */
  }

  .hero-navbar-nav .nav-link.active-nav-link,
  .hero-navbar-nav .nav-link.active {
    background-color: #c7402d !important; /* Active background color */
    color: #ffffff !important; /* Active text color for better contrast */
    border-radius: 5px; /* Maintain rounded corners */
  }
  .sticky-navbar {
    width: 100%;
    border-top: 9px solid rgb(7, 7, 135);

    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px; /* Adjusted height for larger logo */
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 20;
    box-shadow: 0 -8px 15px rgba(0, 0, 0, 0.1),
      /* Shadow projecting at the top */ 0 8px 15px rgba(0, 0, 0, 0.2),
      /* Larger shadow at the bottom */ 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow at the bottom */
    transition: background-color 0.3s ease, height 0.3s ease,
      box-shadow 0.3s ease; /* Smooth shadow transition */
  }

  /* Pseudo-element to blur content above the navbar */

  .sticky-navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Space out logo and menu */
    width: 100%;
    padding: 0 20px;
  }

  .sticky-logo-img {
    height: 140px; /* Increased logo size */
    width: auto;
    margin-right: 40px; /* Adjusted margin to separate logo and menu */
    transition: transform 0.3s ease; /* Add hover animation for logo */
  }

  .sticky-logo-img:hover {
    transform: scale(1.1); /* Slight enlargement on hover */
  }

  .sticky-navbar-nav {
    display: flex;
    align-items: center;
    gap: 15px;
    font-family: "Oswald", sans-serif; /* Stylish font */
    font-size: 17px; /* Slightly larger font */
    font-weight: 600;
    text-align: center;
  }

  .sticky-navbar-nav .nav-link {
    color: black; /* White text for links */
    text-decoration: none;
    width: 120px;
    padding: 8px 15px; /* Add padding for button-like feel */
    border-radius: 5px; /* Rounded corners for nav links */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth hover effect */
  }

  .sticky-navbar-nav .nav-link:hover {
    background-color: #c7402d; /* #c7402dbackground on hover */
    color: #ffffff; /* White text on hover */
  }

  .sticky-navbar-nav .nav-link.active-nav-link,
  .sticky-navbar-nav .nav-link.active {
    background-color: #c7402d; /* Active state background */
    color: #ffffff; /* White text for active state */
    border-radius: 5px; /* Maintain rounded corners */
  }
  .sticky-dropdown-menu {
    background-color: #fff;
    width: 800px;
    height: 240px;
    border-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    position: absolute;
    left: 40%;
    top: 60%;
    transform: translate(-100%, 8%);
    z-index: 2000;
  }
}

/* iPhone 14 Pro Max Portrait Mode */
@media only screen and (min-width: 308px) and (max-width: 432px) and (orientation: portrait) {
  .main-banner {
    margin-top: 100px;
    position: relative;
    height: 90vh; /* Full height */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden; /* Ensure child elements do not overflow */
  }

  .carousel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; /* Ensure the carousel is behind other content */
  }

  .carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh; /* Full height */
  }

  .carousel-item img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: brightness(70%);
  }

  .carousel-caption {
    position: absolute;
    top: 0%;

    color: white; /* Text color */
    z-index: 2; /* Ensure it appears above the carousel */
    pointer-events: none; /* Ensure the caption does not block interactions */
  }

  .hero-text {
    color: white; /* Text color */
    font-family: "Philosopher", sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    padding: 0px; /* Add some padding */
    border-radius: 10px;
    margin-top: 130px; /* Optional: Add rounded corners */
  }

  .hero-text h1 {
    font-size: 65px;
  }

  /* Overlay image common styles */
  .carousel-overlay-image1 {
    position: absolute;
    bottom: 60px; /* Adjust the bottom offset as needed */
    right: -70px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 400px; /* Adjust the width as needed */
    height: 320px; /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image2 {
    position: absolute;
    bottom: 60px; /* Adjust the bottom offset as needed */
    right: -0px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 300px; /* Adjust the width as needed */
    height: 310px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image2 .overlay-image-2 {
    height: 250px;
    width: 300px;
  }
  .carousel-overlay-image3 {
    position: absolute;
    bottom: 70px; /* Adjust the bottom offset as needed */
    right: 0px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 300px; /* Adjust the width as needed */
    height: 310px;

    z-index: 1;
  }

  .carousel-overlay-image3 .overlay-image-3 {
    height: 250px;
    width: 300px;
  }

  .carousel-overlay-image4 {
    position: absolute;
    bottom: 80px; /* Adjust the bottom offset as needed */
    right: -50px; /* Adjust the right offset as needed */
    /* Adjust to center vertically */
    width: 300px; /* Adjust the width as needed */
    height: 310px;
    /* Maintain aspect ratio */
    z-index: 1;
  }

  .carousel-overlay-image4 .overlay-image-4 {
    height: 250px;
    width: 300px;
  }
  .mobile-navbar {
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding-left: 20px;
    padding-right: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
  }

  .mobile-navbar-logo {
    height: 120px;
    width: 120px;
  }

  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 20px;
    cursor: pointer;
  }

  .hamburger-icon .bar {
    width: 25px;
    height: 3px;
    background-color: black;
    transition: all 0.3s ease;
  }

  .hamburger-icon .bar.open:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }

  .hamburger-icon .bar.open:nth-child(2) {
    opacity: 0;
  }

  .hamburger-icon .bar.open:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
  }

  .mobile-menu-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    color: black;
    cursor: pointer;
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mobile-nav .nav-link {
    color: black;
    padding: 10px;
    text-decoration: none;
    font-size: 20px;
    font-family: "Oswald", sans-serif;
    font-weight: 400;
    width: 100%;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }

  .mobile-nav .nav-link:hover {
    background-color: #f0f0f0;
  }
  .mobile-nav .nav-link {
    color: black;
    padding: 10px;
    text-decoration: none;
    font-size: 18px;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s ease;
  }

  .mobile-nav .nav-link:hover {
    background-color: #c7402d; /* Background color on hover */
    color: white; /* Optional: Change text color to white on hover */
  }

  .mobile-nav .nav-link.active {
    background-color: #c7402d; /* Background color for active item */
    color: white; /* Text color for active item */
  }
}
