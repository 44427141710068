.cs-title {
    font-size: 120px;
    font-family: "Yeseva One", sans-serif;
    text-align: center;
    margin-top: 200px;
    margin-bottom: 200px;
}

@media only screen 
and (min-device-width: 818px) 
and (max-device-width: 824px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2) { 

    .cs-title {
        font-size: 120px;
        font-family: "Yeseva One", sans-serif;
        text-align: center;
        margin-top: 200px;
        margin-bottom: 200px;
    }

}

@media only screen and (min-width: 308px) and (max-width: 432px) and (orientation: portrait) {


    .cs-title {
        font-size: 80px;
        font-family: "Yeseva One", sans-serif;
        text-align: center;
        margin-top: 200px;
        margin-bottom: 200px;
    }
    




}