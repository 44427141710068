@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Yeseva+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Judson:ital,wght@0,400;0,700;1,400&display=swap');
.main-wwp-div {
  padding-bottom: 60px;
  background-size: cover;
  background-position: center;
  /* transition: background-image 1.5s ease-in-out;  */
  /* Slow transition for background change */
}

.hrblue {
  width: 100%;
  height: 7px;
  background-color: #2a447f;
  border-radius: 0px;
}

.Wwp-title {
  padding-top: 40px;
  text-align: center;
  /* transition: opacity 0.3s ease;  */
  /* Smooth transition for title visibility */
}

.hidden {
  opacity: 0; /* Hide the title */
}

.Wwp-h2 {
  font-size: 50px;
  color: #2a447f;
  font-family: "Yeseva One", serif;
  font-weight: 400;
  font-style: normal;
}

.span-1 {
  font-size: 50px;
  font-family: "Yeseva One", serif;
  font-weight: 400;
  font-style: normal;
  color: #c7402d;
}

.Wwp-sub-title {
  /* font-family: "Yeseva One", serif; */
  font-weight: 400;
  /* font-style: normal; */
  font-size: 16px;
  color: #525252;
}

.items-outer-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 50px;
  margin-top: 60px;
}

.items-info {
  margin-top: 60px;
  height: 250px;
  flex: 1 1 calc(25% - 20px);
  margin-bottom: 20px;
  box-sizing: border-box;
  transition: transform 0.3s ease; /* Smooth scale effect on hover */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.img-div-Wwp {
  border-top: 4px solid #c7402d;
  /* border-radius: 20px; */
  height: 200px;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-div-Wwp::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.3s ease; /* Smooth transition for overlay */
}

.items-info:hover .img-div-Wwp::after {
  background-color: rgba(255, 255, 255, 0.6); /* Overlay effect on hover */
}

.items-img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire div */
  transition: transform 0.3s ease-in-out; /* Smooth transition for image */
}

.items-info:hover .items-img {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

.items-brief {
  /* background-color: rgb(189, 54, 54); */
  height: 110px;
  /* width: 80%; */
  border-radius: 7px;
  margin-top: -20px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.items-brief-inner {
  /* border: 1px solid white; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.items-h2 {
  color: #bd3636;
  font-size: 18px;
  padding-top: 20px;
  /* font-family: "Yeseva One", serif; */
  font-weight: 700;
  text-align: left;
}

.items-h4 {
  color: #525252;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}

/* Button styling */
.button-Wwp {
  margin-top: 20px;
  
  height: 45px;
  border-radius: 8px;
  padding: 2px 30px;
  margin-left: 10px;
  margin-right: 10px;
  border:none;
  background-color: white;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Transition for button effects */
}

/* .button-Wwp:hover {

  background-color: #c7402d;
  color: white;
} */

.Wwp-span {
  /* font-family: "Judson", serif; */
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 1px;
  color: #c7402d;
  transition: color 0.3s ease;
}

/* .button-Wwp:hover  {

  background-color: rgb(189, 54, 54);
  color: white;
  transform:translateY(-5px);
  box-shadow:  12px 8px 12px rgba(40, 2, 2, 0.7);

  .Wwp-span {
    color: white;
    
  }
} */

.button-Wwp {

  background-color: rgb(189, 54, 54);
  color: white;
  transform:translateY(-5px);
  box-shadow: 3px 8px 15px rgb(0 0 0 / 52%);
  

  .Wwp-span {
    color: white;
    
  }
}


.buttons-kolom {
  margin-top: 40px;
}

/* Hover Effects for items */
.items-info:hover {
  transform: scale(1.05); /* Scale effect on hover */
}

.centuryimg {
  width: 800px;
  height: 103px;
}


/* Media queries for responsiveness */
@media (max-width: 767px) {
  .main-wwp-div {
    margin-top: 100px;
  }

  .Wwp-title {
    padding-top: 30px;
  }

  .Wwp-h2 {
    font-size: 40px;
  }

  .span-1 {
    font-size: 40px;
    color: #c7402d;
  }

  .Wwp-sub-title {
    font-size: 16px;
  }

  .items-outer-div {
    padding-left: 30px;
    padding-right: 30px;
    justify-content: center;
  }

  .items-info {
    flex: 1 1 calc(50% - 20px); /* Each item takes 50% width on smaller screens */
  }

  .img-div-Wwp {
    height: 180px;
  }

  .items-brief {
    height: 100px;
    width: 90%;
    margin-top: 0;
  }

  .items-h2 {
    font-size: 22px;
    padding-top: 8px;
  }

  .items-h4 {
    font-size: 14px;
  }

  .button-Wwp {
    margin-left: 10px;
    margin-right: 10px;
  }

  .buttons-kolom {
    margin-top: 20px;
  }
}

/* ipad pro */
@media only screen and (min-width: 834px) and (max-width: 1024px) and (orientation: portrait) { 

  .centuryimg {
    width: 500px;

  }
  .main-wwp-div {
    padding-bottom: 40px;
    background-size: cover;
    background-position: center;
    transition: background-image 0.3s ease-in-out; /* Smooth transition for background change */
  }
  
  .hrblue {
    width: 100%;
    height: 7px;
    background-color: #2a447f;
    border-radius: 0px;
  }
  
  .Wwp-title {
    padding-top: 40px;
    text-align: center;
    transition: opacity 0.3s ease; /* Smooth transition for title visibility */
  }
  
  .hidden {
    opacity: 0; /* Hide the title */
  }
  
  .Wwp-h2 {
    font-size: 70px;
    color: #2a447f;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .span-1 {
    font-size: 70px;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    color: #c7402d;
  }
  
  .Wwp-sub-title {
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: black;
  }
  
  .items-outer-div {
    display: flex; /* Use flexbox to display items side by side */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: space-between; /* Distribute space between items */
    padding: 0 0px;
    margin-top: 50px;
  }
  
  .items-info {
    height: 250px;
    flex: 1 1 calc(25% - 20px); /* Each item takes 25% of the container width minus the gap */
    margin-bottom: 20px;
    box-sizing: border-box;
    transition: transform 0.3s ease; /* Add a smooth scale effect on hover */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Needed for overlay positioning */
    overflow: hidden; /* Ensures overlay stays within bounds */
  }
  
  .img-div-Wwp {
    border: 1px solid #c7402d;
    border-radius: 20px;
    height: 200px;
    width: 100%;
    overflow: hidden; /* Ensures the image does not overflow its container */
    position: relative; /* Position relative for pseudo-element positioning */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img-div-Wwp::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0); /* Initial transparent overlay */
    transition: background-color 0.3s ease; /* Transition for overlay */
  }
  
  .items-info:hover .img-div-Wwp::after {
    background-color: rgba(255, 255, 255, 0.6); /* White overlay with opacity on hover */
  }
  
  .items-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire div */
    transition: transform 0.3s ease-in-out; /* Smooth transition for image */
  }
  
  .items-info:hover .items-img {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }

  .centuryimg {
    width: 200px;
    height: 70px;

  }
  
  .items-brief {
    background-color: rgb(189, 54, 54);
    height: 130px;
    width: 100%;
    border-radius: 7px;
    margin-top: 0px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1; /* Ensure text is above overlay */
  }
  
  .items-brief-inner {
    border: 1px solid white;
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .items-h2 {
    color: white;
    font-size: 26px;
    padding-top: 10px;
    font-family: "Yeseva One", serif;
    font-weight: 400;
  }
  
  .items-h4 {
    color: white;
    font-size: 16px;
  }
  
  /* Button styling */
  .button-Wwp {
    margin-top: 20px;
    border-radius: 8px;
    padding: 10px 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #c7402d;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Transition for button effects */
  }
  
  .button-Wwp:hover {
    background-color: #c7402d;
    color: white;
  }
  
  .Wwp-span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 24px;
    letter-spacing: 3px;
    color: #c7402d;
    transition: color 0.3s ease;
  }
  
  .buttons-kolom {
    margin-top: 40px;
  }
  
  /* Hover Effects for items */
  .items-info:hover {
    transform: scale(1.05); /* Scale effect on hover */
  }


}



@media only screen and (min-width: 308px) and (max-width: 432px) and (orientation: portrait) {


  .main-wwp-div {
    margin-top: 20px;
    padding-bottom: 40px;
    background-size: cover;
    background-position: center;
    transition: background-image 0.3s ease-in-out; /* Smooth transition for background change */
  }
  
  .hrblue {
    width: 100%;
    height: 7px;
    background-color: #2a447f;
    border-radius: 0px;
  }
  
  .Wwp-title {
    padding-top: 40px;
    text-align: center;
    transition: opacity 0.3s ease; /* Smooth transition for title visibility */
  }
  
  .hidden {
    opacity: 0; /* Hide the title */
  }
  
  .Wwp-h2 {
    font-size: 50px;
    color: #2a447f;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .span-1 {
    font-size: 50px;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    color: #c7402d;
  }
  
  .Wwp-sub-title {
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: black;
  }
  
  .items-outer-div {
    display: flex; /* Use flexbox to display items side by side */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: space-between; /* Distribute space between items */
    padding: 0 50px;
    margin-top: 50px;
  }
  
  .items-info {
    height: 250px;
    flex: 1 1 calc(25% - 20px); /* Each item takes 25% of the container width minus the gap */
    margin-bottom: 20px;
    box-sizing: border-box;
    transition: transform 0.3s ease; /* Add a smooth scale effect on hover */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Needed for overlay positioning */
    overflow: hidden; /* Ensures overlay stays within bounds */
  }
  
  .img-div-Wwp {
    border: 1px solid #c7402d;
    border-radius: 20px;
    height: 200px;
    width: 100%;
    overflow: hidden; /* Ensures the image does not overflow its container */
    position: relative; /* Position relative for pseudo-element positioning */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img-div-Wwp::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0); /* Initial transparent overlay */
    transition: background-color 0.3s ease; /* Transition for overlay */
  }
  
  .items-info:hover .img-div-Wwp::after {
    background-color: rgba(255, 255, 255, 0.6); /* White overlay with opacity on hover */
  }
  
  .items-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire div */
    transition: transform 0.3s ease-in-out; /* Smooth transition for image */
  }
  
  .items-info:hover .items-img {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }

  .centuryimg {
    width: 100%;
    height: 100px;
  }
  
  .items-brief {
    background-color: rgb(189, 54, 54);
    height: 110px;
    width: 80%;
    border-radius: 7px;
    margin-top: -20px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1; /* Ensure text is above overlay */
  }
  
  .items-brief-inner {
    border: 1px solid white;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .items-h2 {
    color: white;
    font-size: 26px;
    padding-top: 10px;
    font-family: "Yeseva One", serif;
    font-weight: 400;
  }
  
  .items-h4 {
    color: white;
    font-size: 16px;
  }
  
  /* Button styling */
  .button-Wwp {
    margin-top: 20px;
    width: 230px;
    border-radius: 8px;
    padding: 2px 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #c7402d;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Transition for button effects */
  }
  
  .button-Wwp:hover {
    background-color: black;
    color: #c7402d;
  }
  
  .Wwp-span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 24px;
    letter-spacing: 3px;
    color: #c7402d;
    transition: color 0.3s ease;
  }
  
  .buttons-kolom {
    margin-top: 40px;
  }
  
  /* Hover Effects for items */
  .items-info:hover {
    transform: scale(1.05); /* Scale effect on hover */
  }

}


@media only screen 
and (min-device-width: 818px) 
and (max-device-width: 824px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 2)

{

 
  .centuryimg {
    width: 500px;

  }
  .main-wwp-div {
    padding-bottom: 40px;
    background-size: cover;
    background-position: center;
    transition: background-image 0.3s ease-in-out; /* Smooth transition for background change */
  }
  
  .hrblue {
    width: 100%;
    height: 7px;
    background-color: #2a447f;
    border-radius: 0px;
  }
  
  .Wwp-title {
    padding-top: 40px;
    text-align: center;
    transition: opacity 0.3s ease; /* Smooth transition for title visibility */
  }
  
  .hidden {
    opacity: 0; /* Hide the title */
  }
  
  .Wwp-h2 {
    font-size: 70px;
    color: #2a447f;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .span-1 {
    font-size: 70px;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    color: #c7402d;
  }
  
  .Wwp-sub-title {
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: black;
  }
  
  .items-outer-div {
    display: flex; /* Use flexbox to display items side by side */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: space-between; /* Distribute space between items */
    padding: 0 0px;
    margin-top: 50px;
  }
  
  .items-info {
    height: 250px;
    flex: 1 1 calc(25% - 20px); /* Each item takes 25% of the container width minus the gap */
    margin-bottom: 20px;
    box-sizing: border-box;
    transition: transform 0.3s ease; /* Add a smooth scale effect on hover */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Needed for overlay positioning */
    overflow: hidden; /* Ensures overlay stays within bounds */
  }
  
  .img-div-Wwp {
    border: 1px solid #c7402d;
    border-radius: 20px;
    height: 200px;
    width: 100%;
    overflow: hidden; /* Ensures the image does not overflow its container */
    position: relative; /* Position relative for pseudo-element positioning */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img-div-Wwp::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0); /* Initial transparent overlay */
    transition: background-color 0.3s ease; /* Transition for overlay */
  }
  
  .items-info:hover .img-div-Wwp::after {
    background-color: rgba(255, 255, 255, 0.6); /* White overlay with opacity on hover */
  }
  
  .items-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire div */
    transition: transform 0.3s ease-in-out; /* Smooth transition for image */
  }
  
  .items-info:hover .items-img {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }

  .centuryimg {
    width: 200px;
    height: 70px;

  }
  
  .items-brief {
    background-color: rgb(189, 54, 54);
    height: 130px;
    width: 100%;
    border-radius: 7px;
    margin-top: 0px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1; /* Ensure text is above overlay */
  }
  
  .items-brief-inner {
    border: 1px solid white;
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .items-h2 {
    color: white;
    font-size: 26px;
    padding-top: 10px;
    font-family: "Yeseva One", serif;
    font-weight: 400;
  }
  
  .items-h4 {
    color: white;
    font-size: 16px;
  }
  
  /* Button styling */
  .button-Wwp {
    margin-top: 20px;
    border-radius: 8px;
    padding: 10px 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #c7402d;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Transition for button effects */
  }
  
  .button-Wwp:hover {
    background-color: #c7402d;
    color: white;
  }
  
  .Wwp-span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 24px;
    letter-spacing: 3px;
    color: #c7402d;
    transition: color 0.3s ease;
  }
  
  .buttons-kolom {
    margin-top: 40px;
  }
  
  /* Hover Effects for items */
  .items-info:hover {
    transform: scale(1.05); /* Scale effect on hover */
  }


}



@media only screen 
and (min-device-width: 1178px) 
and (max-device-width: 1182px) 
and (orientation: landscape) 
and (-webkit-min-device-pixel-ratio: 2)

{

 
  .centuryimg {
    width: 500px;

  }
  .main-wwp-div {
    padding-bottom: 40px;
    background-size: cover;
    background-position: center;
    transition: background-image 0.3s ease-in-out; /* Smooth transition for background change */
  }
  
  .hrblue {
    width: 100%;
    height: 7px;
    background-color: #2a447f;
    border-radius: 0px;
  }
  
  .Wwp-title {
    padding-top: 40px;
    text-align: center;
    transition: opacity 0.3s ease; /* Smooth transition for title visibility */
  }
  
  .hidden {
    opacity: 0; /* Hide the title */
  }
  
  .Wwp-h2 {
    font-size: 70px;
    color: #2a447f;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .span-1 {
    font-size: 70px;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    color: #c7402d;
  }
  
  .Wwp-sub-title {
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: black;
  }
  
  .items-outer-div {
    display: flex; /* Use flexbox to display items side by side */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: space-between; /* Distribute space between items */
    padding: 0 0px;
    margin-top: 50px;
  }
  
  .items-info {
    height: 250px;
    flex: 1 1 calc(25% - 20px); /* Each item takes 25% of the container width minus the gap */
    margin-bottom: 20px;
    box-sizing: border-box;
    transition: transform 0.3s ease; /* Add a smooth scale effect on hover */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Needed for overlay positioning */
    overflow: hidden; /* Ensures overlay stays within bounds */
  }
  
  .img-div-Wwp {
    border: 1px solid #c7402d;
    border-radius: 20px;
    height: 200px;
    width: 100%;
    overflow: hidden; /* Ensures the image does not overflow its container */
    position: relative; /* Position relative for pseudo-element positioning */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img-div-Wwp::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0); /* Initial transparent overlay */
    transition: background-color 0.3s ease; /* Transition for overlay */
  }
  
  .items-info:hover .img-div-Wwp::after {
    background-color: rgba(255, 255, 255, 0.6); /* White overlay with opacity on hover */
  }
  
  .items-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire div */
    transition: transform 0.3s ease-in-out; /* Smooth transition for image */
  }
  
  .items-info:hover .items-img {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }

  .centuryimg {
    width: 200px;
    height: 70px;

  }
  
  .items-brief {
    background-color: rgb(189, 54, 54);
    height: 130px;
    width: 100%;
    border-radius: 7px;
    margin-top: 0px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1; /* Ensure text is above overlay */
  }
  
  .items-brief-inner {
    border: 1px solid white;
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .items-h2 {
    color: white;
    font-size: 26px;
    padding-top: 10px;
    font-family: "Yeseva One", serif;
    font-weight: 400;
  }
  
  .items-h4 {
    color: white;
    font-size: 16px;
  }
  
  /* Button styling */
  .button-Wwp {
    margin-top: 20px;
    border-radius: 8px;
    padding: 10px 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #c7402d;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Transition for button effects */
  }
  
  .button-Wwp:hover {
    background-color: #c7402d;
    color: white;
  }
  
  .Wwp-span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 24px;
    letter-spacing: 3px;
    color: #c7402d;
    transition: color 0.3s ease;
  }
  
  .buttons-kolom {
    margin-top: 40px;
  }
  
  /* Hover Effects for items */
  .items-info:hover {
    transform: scale(1.05); /* Scale effect on hover */
  }


}



/* ipad pro */
@media only screen and (min-width: 1364px) and (max-width: 1368px) and (orientation: landscape) { 

 
  .main-wwp-div {
    padding-bottom: 40px;
    background-size: cover;
    background-position: center;
    transition: background-image 0.3s ease-in-out; /* Smooth transition for background change */
  }
  
  .hrblue {
    width: 100%;
    height: 7px;
    background-color: #2a447f;
    border-radius: 0px;
  }
  
  .Wwp-title {
    padding-top: 40px;
    text-align: center;
    transition: opacity 0.3s ease; /* Smooth transition for title visibility */
  }
  
  .hidden {
    opacity: 0; /* Hide the title */
  }
  
  .Wwp-h2 {
    font-size: 70px;
    color: #2a447f;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .span-1 {
    font-size: 70px;
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    color: #c7402d;
  }
  
  .Wwp-sub-title {
    font-family: "Yeseva One", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: black;
  }
  
  .items-outer-div {
    display: flex; /* Use flexbox to display items side by side */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: space-between; /* Distribute space between items */
    padding: 0 0px;
    margin-top: 50px;
  }
  
  .items-info {
    height: 250px;
    flex: 1 1 calc(25% - 20px); /* Each item takes 25% of the container width minus the gap */
    margin-bottom: 20px;
    box-sizing: border-box;
    transition: transform 0.3s ease; /* Add a smooth scale effect on hover */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Needed for overlay positioning */
    overflow: hidden; /* Ensures overlay stays within bounds */
  }
  
  .img-div-Wwp {
    border: 1px solid #c7402d;
    border-radius: 20px;
    height: 200px;
    width: 100%;
    overflow: hidden; /* Ensures the image does not overflow its container */
    position: relative; /* Position relative for pseudo-element positioning */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img-div-Wwp::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0); /* Initial transparent overlay */
    transition: background-color 0.3s ease; /* Transition for overlay */
  }
  
  .items-info:hover .img-div-Wwp::after {
    background-color: rgba(255, 255, 255, 0.6); /* White overlay with opacity on hover */
  }
  
  .items-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire div */
    transition: transform 0.3s ease-in-out; /* Smooth transition for image */
  }
  
  .items-info:hover .items-img {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }

  .centuryimg {
    width: 240px;
    height: 80px;

  }
  
  .items-brief {
    background-color: rgb(189, 54, 54);
    height: 100px;
    width: 100%;
    border-radius: 7px;
    margin-top: 0px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1; /* Ensure text is above overlay */
  }
  
  .items-brief-inner {
    border: 1px solid white;
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .items-h2 {
    color: white;
    font-size: 26px;
    padding-top: 10px;
    font-family: "Yeseva One", serif;
    font-weight: 400;
  }
  
  .items-h4 {
    color: white;
    font-size: 16px;
  }
  
  /* Button styling */
  .button-Wwp {
    margin-top: 20px;
    border-radius: 8px;
    padding: 10px 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #c7402d;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease; /* Transition for button effects */
  }
  
  .button-Wwp:hover {
    background-color: #c7402d;
    color: white;
  }
  
  .Wwp-span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 24px;
    letter-spacing: 3px;
    color: #c7402d;
    transition: color 0.3s ease;
  }
  
  .buttons-kolom {
    margin-top: 40px;
  }
  
  /* Hover Effects for items */
  .items-info:hover {
    transform: scale(1.05); /* Scale effect on hover */
  }


}

