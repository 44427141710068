.outer-main-div {
  height: auto;
}

.review-slider {
  padding: 40px 40px;
  margin: 250px auto;
  margin-bottom: 50px;
  max-width: 1200px;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Whitish color with transparent background */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a subtle shadow for depth */
  border-radius: 10px;
}

.title-review-img {
  position: absolute;
  top: 525%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the image */
  height: 280px;
  z-index: -1;
}

.review-title {
  text-align: center;
  font-size: 40px;
  font-family: "Yeseva One", serif;
  color: rgb(27, 27, 160);
  margin-bottom: 50px;
}

.review-title-highlight {
  color: red;
}

.review-card-wrapper {
  padding: 10px;
  display: flex;
  justify-content: center; /* Ensure items are centered */
}

.review-card {
  display: flex;
  flex-direction: row; /* Ensure horizontal row layout */
  padding: 20px;
  height: 300px;
  border: 1px solid red;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  justify-content: flex-start;
  background-color: white;
  width: 100%; /* Make sure the card takes full width of its container */
}

.img-review-div {
  flex: 0 0 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.content-review {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.review-msg {
  font-style: italic;
  margin-bottom: 10px;
}

.review-name {
  font-weight: 600;
  font-family: "Oswald", sans-serif;
  margin-top: 5px;
}

/* Custom styles for Slick Slider Arrows */
.slick-prev:before,
.slick-next:before {
  color: red;
}

@media only screen and (max-width: 768px) {
  .review-card {
    flex-direction: column;
    text-align: center;
    height: auto;
  }

  .img-review-div {
    margin-bottom: 15px;
  }

  .review-title {
    font-size: 30px;
  }

  .review-img {
    width: 100px;
    height: 100px;
  }

  .title-review-img {
    display: none;
  }
}

@media only screen and (max-width: 3000px) {
  .title-review-img {
    display: none;
  }
}
