.main-contact-div {
  margin-top: 110px;
  background-color: white;
  padding: 40px 0;
}

/* Map container */
.map-container {
  height: 400px;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  border-radius: 10px;
  margin: 50px auto;
}

.map-container iframe {
  width: 100%;
  height: 100%;
}

.random-contact-img {
  display: block;
  width: 100%;
  max-width: 600px; /* Ensure the image does not exceed the container width */
  margin: 20px auto 0 auto; /* Adds space between the image and map */
  border-radius: 10px;
}

/* Form */
.contact-form {
  max-width: 600px;
  margin: 0 auto;
  background: white;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.form-header {
  text-align: center;
  margin-bottom: 30px;
}

.form-header h1 {
  font-size: 40px;
  color: #004fab;
  font-family: "Yeseva One", sans-serif;
}

.form-header p {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

textarea.form-control {
  resize: none;
}

.submit-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #ff0077;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #e60065;
}

.random-contact-img {
  height: 150px;
  width: 150px;
}

@media only screen and (min-width: 834px) and (max-width: 1024px) and (orientation: portrait) {
  .main-contact-div {
    margin-top: 110px;
    background-color: white;
    padding: 140px 0;
  }

  /* Map container */
  .map-container {
    height: 400px;
    width: 100%;
    max-width: 500px;
    overflow: hidden;
    border-radius: 10px;
    margin: 50px auto;
  }

  /* Form */
  .contact-form {
    max-width: 450px;
    margin: 0 auto;
    background: white;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-device-width: 818px) and (max-device-width: 824px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .main-contact-div {
    margin-top: 110px;
    background-color: white;
    padding: 35px 0;
  }

  /* Map container */
  .map-container {
    height: 400px;
    width: 100%;
    max-width: 400px;
    overflow: hidden;
    border-radius: 10px;
    margin: 50px auto;
  }

  /* Form */
  .contact-form {
    max-width: 380px;
    margin: 0 auto;
    background: white;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 765px) and (max-width: 770px) and (orientation: portrait) {
  .main-contact-div {
    margin-top: 110px;
    background-color: white;
    padding: 50px 0;
  }

  /* Map container */
  .map-container {
    height: 400px;
    width: 100%;
    max-width: 390px;
    overflow: hidden;
    border-radius: 10px;
    margin: 50px auto;
  }

  /* Form */
  .contact-form {
    max-width: 450px;
    margin: 0 auto;
    background: white;
    padding: 30px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}
