.outer-div-aboutpage {
  position: relative;
  margin: 90px auto;
  padding: 0;
  max-width: 1200px;
}

.about-us-heading-section {
  position: relative;
  top: 50px;
  width: 60%;
  text-align: left;
  margin-bottom: 40px;
  padding: 20px;
}

.about-us-title {
  font-size: 40px;
  font-family: "Yeseva One";
  font-weight: bold;
  color: #333;
}

.about-us-description {
  font-size: 40px;
  font-family: "Yeseva One";
  color: black;
  line-height: 1.2;
}

.about-us-bookmark {
  position: fixed;
  width: 130px;
  top: 20%;
  left: 0px;
  background-color: #ff6b6b;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.image-area {
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  height: 100%;

  margin-top: 0px;
}

.tabs-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 20px;
  width: 65%;
}

.tab-item {
  font-family: "Times New Roman", Times, serif;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 2px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  background-color: white;
  text-align: center;
  height: 50px;
}

.tab-item.active {
  background-color: rgb(241, 95, 95);
  border-color: #ff6b6b;
  color: white;
}

.tab-text {
  color: inherit;
  text-transform: uppercase;
}

.content-area {
  background-color: #f8f9fa;
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 1px solid #c7402d;

  height: 480px;
}

.content-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.content-title {
  font-family: "Yeseva One", serif;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
}

.content-description {
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 18px;
  line-height: 1.6;
  color: black;
  margin-bottom: 25px;
}

.content-list {
  list-style: none;
  padding: 0;
}

.content-list-item {
  font-size: 17px;
  margin: 8px 0;
  padding-left: 25px;
  position: relative;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
}

.content-list-item::before {
  content: "✔";
  position: absolute;
  left: 0;
  color: #ff6b6b;
  font-size: 18px;
}

.img-fluid {
  width: 100%;
  object-fit: cover;
  height: 450px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

button.learn-more-btn {
  background-color: #ff6b6b;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.learn-more-btn:hover {
  background-color: #ff4c4c;
}

@media only screen and (min-width: 308px) and (max-width: 432px) and (orientation: portrait) {
  /* General Layout */
  .outer-div-aboutpage {
    margin-top: 80px;
    padding: 0;
  }

  /* About Us Description */
  .about-us-heading-section {
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally center the content */
    justify-content: center; /* Vertically center the content */
    text-align: center; /* Ensure text is centered */
    width: 100%; /* Take full width */
    margin: 0 auto; /* Center the container */
    padding: 20px;
    padding-bottom: 20px;
  }

  .about-us-description {
    font-size: 25px; /* Adjust font size for smaller screens */
    line-height: 1.4;
    color: #333; /* Ensure good contrast */
    padding-bottom: 30px;
  }

  /* Tabs Container */
  .tabs-container {
    flex-direction: column; /* Stack tabs vertically */
    width: 100%; /* Full width for smaller screens */
    gap: 10px; /* Reduce gap between tabs */
  }

  .tab-item {
    padding: 10px; /* Reduce padding for smaller tabs */
    height: auto; /* Let height adjust dynamically */
    font-size: 14px; /* Smaller font size for tabs */
  }

  .tab-item.active {
    font-size: 16px; /* Highlight active tab with slightly larger font */
  }

  /* Content Area */
  .content-area {
    padding: 20px; /* Reduce padding */
    height: auto; /* Let the height adjust dynamically */
  }

  .content-title {
    font-size: 24px; /* Smaller title font */
    text-align: center; /* Center-align title */
    margin-bottom: 10px;
  }

  .content-description {
    font-size: 14px; /* Reduce description font size */
    text-align: justify; /* Justify text for better alignment */
    margin-bottom: 15px;
  }

  .content-list-item {
    font-size: 14px; /* Smaller font for list items */
    padding-left: 20px; /* Adjust padding for bullets */
  }

  .content-list-item::before {
    font-size: 14px; /* Smaller bullet size */
    left: 0;
  }

  /* Image Area */
  .image-area {
    padding: 10px; /* Reduce padding for image container */
    margin: 0 auto; /* Center the image container */
  }

  .img-fluid {
    height: 250px; /* Smaller height for images */
    border-radius: 12px; /* Adjust border radius */
  }

  /* About Us Bookmark */
  .about-us-bookmark {
    width: 100px; /* Reduce width */
    font-size: 14px; /* Smaller font size */
    padding: 8px 12px; /* Adjust padding */
  }

  /* Button */
  button.learn-more-btn {
    width: 100%; /* Full width for buttons */
    font-size: 14px; /* Smaller font size */
    padding: 12px; /* Adjust padding */
    text-align: center;
  }
}
