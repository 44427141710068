.main-div-save {
    margin-top: 200px;
    margin-bottom: 200px;

}
.sub-main-save {
    background-color: rgba(240, 248, 255, 0.9);
    padding-top: 150px;
    padding-bottom: 150px;
}

.save-blue-div {
    background-color: rgb(32, 32, 195);
    height: 300px;
    margin-right: 60px;
    margin-left: 60px;
   
    
}

.circle-div {
    position: absolute;
    margin-top: 100px;
    width: 370px;
    height: 370px;
    border-radius: 200px;
    background-color: rgb(32, 32, 195);
    border: 10px solid #c7402d;
}

.save-img-girl {
    height: 100%;
}

.tag-div {
    position: absolute;
    left: 30px;
    height: 300px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.tag-img {
    height: 200%;
    width: 170%;
    margin-top: -220px;
    
}

.first-purchase {
    position: absolute;
    left: 55%;
    margin-left: 0px; /* Adjust this value as needed */
    font-size: 24px;
    color: white;
}

.first-text {
    margin-top: 80px;
    margin-left: 15px;
    font-size: 60px;
    text-transform: uppercase;
    color: white;
}


.first {
    font-size: 60px;
    font-weight: 700;
}

.purchase {
    font-size: 34px;
    font-weight: 400;
}

.sparkle-div {
    
    height: 180px;
    margin-top: -40px;
    margin-right: 30px;
    
    z-index: 1;
    
}

.sparkle-img {
    height: 100%;
    
    
}

.sparkle-2 {
    
}

.sparkle-img-2 {
    position: absolute;
    height: 300px;
    width: 300px;
    margin-left: 200px;
}

@media only screen and (max-width: 767px) {


    .main-div-save {
        margin-top: 200px;
        margin-bottom: 200px;
    
    }
    .sub-main-save {
        background-color: rgba(240, 248, 255, 0.9);
        padding-top: 150px;
        padding-bottom: 150px;
    }
    
    .save-blue-div {
        background-color: rgb(32, 32, 195);
        height: 300px;
        margin-right: 60px;
        margin-left: 60px;
       
        
    }
    
    .circle-div {
        position: absolute;
        margin-top: 100px;
        width: 370px;
        height: 370px;
        border-radius: 200px;
        background-color: rgb(32, 32, 195);
        border: 10px solid #c7402d;
    }
    
    .save-img-girl {
        height: 100%;
    }
    
    .tag-div {
        position: absolute;
        left: 30px;
        height: 300px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
    }
    
    .tag-img {
        height: 200%;
        width: 170%;
        margin-top: -220px;
        
    }
    
    .first-purchase {
        position: absolute;
        left: 55%;
        margin-left: 0px; /* Adjust this value as needed */
        font-size: 24px;
        color: white;
    }
    
    .first-text {
        margin-top: 80px;
        margin-left: 15px;
        font-size: 60px;
        text-transform: uppercase;
        color: white;
    }
    
    
    .first {
        font-size: 60px;
        font-weight: 700;
    }
    
    .purchase {
        font-size: 34px;
        font-weight: 400;
    }
    
    .sparkle-div {
        
        height: 180px;
        margin-top: -40px;
        margin-right: 30px;
        
        z-index: 1;
        
    }
    
    .sparkle-img {
        height: 100%;
        
        
    }
    
    .sparkle-2 {
        
    }
    
    .sparkle-img-2 {
        position: absolute;
        height: 300px;
        width: 300px;
        margin-left: 0px;
    }
    


}