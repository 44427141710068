.productshowcase {
  margin-top: 120px;
  padding-top: 20px;
}

.card {
  /* border: 1px solid #c7402d; */
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.image-product-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-img-top {
  max-width: 80%;
  height: 300px;
}

.card-body {
  padding: 10px 0;
}

.btn-primary {
  background-color: #da2140;
  border: none;
  font-family: "Oswald", sans-serif;
  font-size: 19px;
}

.btn-primary:hover {
  background-color: #cc0052;
}

/* Footer placeholder */
.footer-placeholder {
  height: 200px;
}

/* Modal navbar for View Other Items */
.product-modal-navbar {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  z-index: 1000;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.product-modal-view-btn {
  background-color: white;
  color: black;
  padding: 12px 35px;
  font-size: 20px;
  border-radius: 5px;
  border: 2px solid #da2140;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
}

.product-modal-view-btn:hover {
  background-color: #da2140;
  color: white;
}

/* Modal styling */
.product-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.product-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.product-modal-content button {
  display: block;
  margin: 10px 0;
  padding: 10px 20px;
  background-color: #da2140;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  font-family: "Oswald", sans-serif;
}

.product-modal-content button:hover {
  background-color: #c51a33;
}

.product-modal-close-btn {
  background-color: white !important;
  padding: 8px 16px;
  margin-top: 20px;
  border: 1px solid black !important;
  color: black !important;
  cursor: pointer;
  width: 100%;
}
/* 
mobile devices */
@media only screen and (min-width: 308px) and (max-width: 432px) and (orientation: portrait) {
  .card-img-top {
    max-width: 57%;
    height: 250px;
  }
}

@media only screen and (min-width: 834px) and (max-width: 1024px) and (orientation: portrait) {
  .card-img-top {
    max-width: 100%;
    height: 300px;
  }
}

@media only screen and (min-device-width: 818px) and (max-device-width: 824px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .card-img-top {
    max-width: 100%;
    height: 300px;
  }
}

@media only screen and (min-width: 765px) and (max-width: 770px) and (orientation: portrait) {
  .card-img-top {
    max-width: 100%;
    height: 300px;
  }
}
